<template>
  <b-container class="wrapper__container form__container">
    <b-img class="woman__image" :src="require('@/assets/woman.png')"></b-img>
    <b-container class="wrapper_header__container">
      <h1>Schneller Kredit-Online-Check</h1>
      <p>Prüfen Sie jetzt Ihre Kreditwürdigkeit!</p>
    </b-container>
    <b-container class="bg__color">
      <question-form></question-form>
    </b-container>
    <b-container class="footer__container">
      <b-img :src="require('@/assets/certificate.png')" fluid></b-img>
    </b-container>
  </b-container>
</template>

<script>
import defaultConfig from "../config/default";
import QuestionForm from "../components/form/QuestionForm.vue";
export default {
  name: "Home",
  components: {
    QuestionForm,
  },
  data() {
    return {
      questions: defaultConfig.questions,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper_header__container {
  padding: 40px 20px;
}
.form__container {
  position: relative;
  color: #025170;
  font-size: 18px;
}
.woman__image {
  position: absolute;
  right: -210px;
  top: 15px;
  z-index: 999;
}
h1 {
  font-weight: 700;
  line-height: 1.231;
  text-transform: uppercase;
}
.footer__container {
  margin-top: 100px;
  padding: 0px 100px;
  margin-bottom: 30px;
}

@media (max-width: 992px) {
  .wrapper_header__container {
    padding: 20px 0px;
  }
  h1 {
    text-align: center;
  }
  .woman__image {
    display: none;
  }
  .footer__container {
    margin-top: 60px;
    padding: 10px;
    margin-bottom: 30px;
  }
  .wrapper_header__container p {
    text-align: center;
    font-weight: 600;
  }
}
</style>
