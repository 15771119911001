<template>
  <b-container>
    <b-container v-if="loading" class="loading__container">
      <div class="loading__item">
        <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
      </div>
    </b-container>
    <b-form @submit.prevent="onSubmit" v-if="show">
      <b-container class="question__container p-3 mb-2">
        <h2 class="border-bottom pb-3 mb-4">
          {{ config.questions[0].question }}
        </h2>

        <b-form-group class="mb-3" v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-for="(item, i) of config.questions[0].answers"
            :key="i"
            v-model="question1"
            :aria-describedby="ariaDescribedby"
            name="question1"
            :value="item.value"
            >{{ item.text }}</b-form-radio
          >
        </b-form-group>
      </b-container>

      <b-container class="question__container p-3 mb-2">
        <h2 class="border-bottom pb-3 mb-4">
          {{ config.questions[1].question }}
        </h2>
        <b-form-group class="mb-3" v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-for="(item, i) of config.questions[1].answers"
            :key="i"
            v-model="question2"
            :aria-describedby="ariaDescribedby"
            name="question2"
            :value="item.value"
            >{{ item.text }}</b-form-radio
          >
        </b-form-group>
      </b-container>

      <b-container class="question__container p-3 mb-2">
        <h2 class="border-bottom pb-3 mb-4">
          {{ config.questions[2].question }}
        </h2>
        <b-form-group class="mb-3" v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-for="(item, i) of config.questions[2].answers"
            :key="i"
            v-model="question3"
            :aria-describedby="ariaDescribedby"
            name="question3"
            :value="item.value"
            >{{ item.text }}</b-form-radio
          >
        </b-form-group>
      </b-container>

      <b-container v-if="!checkQuestions" class="error_message__container">
        <b-icon-asterisk></b-icon-asterisk> Sie müssen alle Felder ausfüllen um
        fortzufahren!
      </b-container>

      <b-button
        v-if="checkQuestions"
        size="lg"
        type="submit"
        class="submit__button"
        :disabled="!checkQuestions"
      >
        <span>Jetzt prüfen!</span>
      </b-button>
    </b-form>

    <b-container v-else>
      <b-container class="error_message__container">
        <h1 class="mb-3">
          <b-icon-emoji-frown></b-icon-emoji-frown> Tut uns leid!
        </h1>
        <p>Sie erfüllen nicht die Anforderungen um einen Kredit zu erhalten.</p>
      </b-container>
    </b-container>
  </b-container>
</template>

<script>
import config from "@/config/default.js";
export default {
  data() {
    return {
      config,
      show: true,
      loading: false,
      question1: "",
      question2: "",
      question3: "",
      blocked: true,
    };
  },
  methods: {
    onSubmit() {
      if (
        this.question1 === "no" ||
        this.question1 === "not" ||
        this.question2 === "no" ||
        this.question3 === "no"
      ) {
        // reditect if question 3 is okay
        if (this.question3 === "okay") {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.$router.push("/success");
          }, 3000);
        }
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.show = false;
        }, 3000);
      } else {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.$router.push("/success");
        }, 3000);
      }
    },
  },
  computed: {
    checkQuestions() {
      let results =
        this.question1 !== "" && this.question2 !== "" && this.question3 !== "";
      return results;
    },
  },
};
</script>

<style lang="scss" scoped>
.loading__container {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  color: #fff;
  text-align: center;
}
.loading__item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.question__container {
  border: 1px solid #ccc;
}
.custom-select {
  width: 100%;
  border-radius: 0px;
  border: 1px solid #ccc;
  padding: 5px;
}
.submit__button {
  background-color: #427581;
  border-color: #427581;
  font-weight: bold;
  margin-top: 30px;
}
@media (max-width: 992px) {
  .submit__button {
    width: 100%;
  }
  h2 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
}
</style>
